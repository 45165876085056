<template>
  <div class="flex flex1 height_100p">
    <Sidebar showTag :tagList="tagList" @searchList="searchList" @changeTags="changeTags" @toShare="toShare"></Sidebar>
    <div class="course-container flex1">
      <div class="list-toolbar flex flex_jcbetween flex_acenter">
        <div class="flex3 fontsize_14 color_222 flexshrink">
          <fdDropdown v-model="sort_mode" :list="orderList" @change="changeOrder"></fdDropdown>
        </div>
      </div>
      <el-scrollbar 
        class="list-container" 
        :wrapStyle="[{'overflow-x':'hidden'}]"
        :class="{ 'margin_top16': currentStyle == 0, 'list_nodata': total == 0, 'grid_type': courseListLen }"
        ref="infiniteScroll"
        :style="{'--right':'-38px'}">
        <div class="coursebox-wrapper bgc_fff pointer" v-for="(item, ind) in courseList" :key="item.id+ '_0'"
          v-show="currentStyle == 0" @click.stop="handleTools({ type: 'edit', item: item, index: ind })">
          <div class="coursebox-image prel" 
            :style="{
              'background-image': `url(${item.course_info?item.course_info.cover:'https://feidao-tools.oss-cn-shenzhen.aliyuncs.com/resources/course_default.jpg'})`}">
            <!-- <el-image :src="item.course_info?item.course_info.cover:''" class="coursebox-image" fit="cover">
              <div slot="error" class="image-slot">
                <img src="https://feidao-tools.oss-cn-shenzhen.aliyuncs.com/resources/course_default.jpg" alt="">
              </div>
            </el-image> -->
            <div class="coursebox-mask" :class="{ show: isshow && showIndex == ind }">
              <el-dropdown @click.stop="" @command="handleTools" @visible-change="toolsVisibleChange"
                class="coursebox-mask-edit" :show-timeout="500" :hide-timeout="200" placement="bottom">
                <span @click.stop="" class="el-dropdown-link coursebox-mask-edit pointer">
                  <i class="icon-gray-more fontsize_18"></i>
                </span>
                <el-dropdown-menu slot="dropdown" @mouseenter.native="toolshover(1, ind)"
                  @mouseleave.native="toolshover(2, ind)" :append-to-body="false">
                  <!-- <el-dropdown-item :command="commandVal('addlabel', item, ind)">{{ $t("lang.addlabel") }}
                  </el-dropdown-item> -->
                  <el-dropdown-item :command="commandVal('delete', item, ind)" divided><span class="color_E74362">{{
                  $t("lang.delete") }}</span></el-dropdown-item>
                </el-dropdown-menu>
              </el-dropdown>
              <div class="coursebox-mask-tools pointer" v-if="item.is_valid==2">
                <i class="icon-share-fail"></i>
                <span class="fontsize_14 color_fff">{{ $t("lang.expired") }}</span>
              </div>
            </div>
          </div>
          <div class="
              coursebox-title
              fontsize_14
              color_222
              padding_lr16 pover
            " v-html="item.course_info?item.course_info.title:'-'"></div>
          <div class="
              coursebox-time
              fontsize_12
              color_999
              padding_bot8
              margin_top3
              padding_lr16
            ">
            <span v-if="item.end_time_type == 2">失效日期：</span>{{item.end_time || '-'}}
          </div>
          <div class="
              coursebox-tools
              fontsize_12
              padding_bot8
              padding_top8
              padding_lr16
            ">
            <div class="coursebox-tools-btn" :class="{'btn-disabled':item.is_valid==2}" @click.stop="shareLink(item)">分享链接</div>
            <div class="coursebox-tools-btn" :class="{'btn-active':true}" @click.stop="shareSet(item)">分享设置</div>
          </div>
        </div>
        
        <div class="nodata" v-if="total == 0">
          <img v-if="keyword != ''" src="@/assets/nodata/course_search_nodata.svg" alt="" class="statu_img">
          <img v-else src="@/assets/nodata/couse_nodata.svg" alt="" class="statu_img">
          <span class="margin_top16 fontsize_12 color_A1A1A1">{{ keyword != '' ? '无搜索内容' : '暂无课程'}}</span>
        </div>
      </el-scrollbar>
      <fd-dialog :title="dialogDetail.title" :visible.sync="dialogVisible" width="600px" :before-close="handleClose"
        custom-class="course-dialog not-full">
        <p class="fontsize_14 color_222 margin_bot8">{{dialogDetail.label}}</p>
        <el-form @submit.native.prevent>
          <el-form-item>
            <el-input v-if="dialogDetail.type !='addlabel'" v-model="inputVal" autocomplete="off"
              :placeholder="dialogDetail.placeholder" :disabled="true" class="h48 border_D1 noborder"></el-input>
            <el-select v-else v-model="dialogDetail.oldTag" value-key="tag_name" disabled
              @change="changeSelectTag($event,'')" multiple filterable remote default-first-option
              class="select-allow-create noborder width_100p"
              style="width:100%">
              <el-option v-for="item in tagList" :key="item.id" :label="item.tag_name" :value="item.tag_name">
              </el-option>
            </el-select>
          </el-form-item>
        </el-form>
        <!-- <span slot="footer" class="dialog-footer">
          <button type="primary" @click="save" class="course-save pointer">保存</button>
          <button @click="dialogVisible = false" class="course-close pointer">关闭</button>
        </span> -->
      </fd-dialog>
      <fd-dialog :title="dialogDetail.title" :visible.sync="delVisible" width="600px" :before-close="handleClose"
        custom-class="course-dialog not-full">
        <p class="fontsize_16 color_222 textcenter margin_bot16" style="padding-top:9px;">请确认是否删除分享</p>
        <p class="fontsize_14 color_E74362 textcenter" style="padding-bottom:9px;">您确认要删除分享吗？删除之后将无法找回分享</p>
        <span slot="footer" class="dialog-footer">
          <button type="primary" @click="delShare" class="course-save pointer">确定删除</button>
          <button @click="delVisible = false" class="course-close pointer">不删了</button>
        </span>
      </fd-dialog>
     
    </div>

    <shareDialog :visible.sync="shareVisible" :shareData="shareData" :entryType="entryType" :update="updateLink"></shareDialog>
    <shareLinkDialog :visible.sync="shareLinkVisible" :shareData="shareData" ></shareLinkDialog>
  </div>
</template>

<script>
import Config from '@/libs/config';
import { debounce } from '@/libs/utils/tools';
import { mapActions, mapMutations, mapState } from 'vuex';
import { addCourseTag, updateCourseTag } from '@/libs/api/course';
import { Sidebar } from '@/renderer/components/Dashboard/components';
import fdDropdown from '@/renderer/components/FormItem/fd-dropdown.vue';
import shareDialog from '@/renderer/components/common/shareDialog.vue';
import shareLinkDialog from '@/renderer/components/common/shareLinkDialog.vue';
export default {
  components: {
    Sidebar,
    fdDropdown,
    shareDialog,
    shareLinkDialog
  },
  data() {
    return {
      courseList: [],
      page: 1,
      total: 0,
      limit: 20,
      keyword: "",
      tagKey: "",
      tagList: [], //标签列表
      sort_mode: "default", //课程排序模式
      orderList: [
        { label: "最近的", value: "default" },
        { label: "按字母a到z顺序", value: "code_asc" },
        { label: "按字母z到a顺序", value: "code_desc" },
      ],
      currentStyle: 0,
      isshow: false,
      showIndex: -1,
      dialogDetail: {
        title: "",
        placeholder: "",
        label: "",
        item: {},
        oldTag: [],
        index: -1,
      },
      dialogVisible: false,
      delVisible: false,
      inputVal: "",
      shareVisible: false,
      shareLinkVisible: false,
      shareData:{},
      entryType:'',
      infiniteScrollDistance: 100, //触底距离
     
    };
  },
  watch: {
    $route() {
      this.total = -1;
      this.sort_mode = "default";
      this.keyword = "";
      this.tagKey = "";
      this.courseList = [];
      this.getList();
    }
  },
  computed: {
    courseListLen() {
      return this.courseList.length
    }
  },
  mounted() {
    // 获取课程列表
    this.getList();
    this.getTagList();

    this.$refs.infiniteScroll.$refs.wrap.onscroll = this.onscroll;
  },
  methods: {
    ...mapActions([
      'GetUserInfo',
      'GetShareCourseList',
      'DelCourse',
      'GetCourseTagList',
      'ShareDetail',
      'DeleteShare',
    ]),
    onscroll() {
      let { offsetHeight, scrollTop, scrollHeight } = this.$refs.infiniteScroll.$refs.wrap;
      scrollHeight = scrollHeight - this.infiniteScrollDistance;
      if (offsetHeight + scrollTop >= scrollHeight) {
        // reach bottom
        this.loadData();
      }
    },
    loadData() {
      // 加载下一页
      if (this.page * this.limit >= this.total) return;
      this.page++;
      this.getList();
    },
    getTagList(tag_name) {
      // 获取标签列表
      this.GetCourseTagList(tag_name ? tag_name : '').then(res => {
        if (res.errorcode == 0) {
          this.tagList = res.data.data;
          // this.fileTagList = res.data.data;
        }
      }).catch();
    },
    searchList: debounce(
      function ({ keyword }) {
        // 通过关键词搜索
        if (this.keyword === keyword) return;
        this.keyword = keyword;
        this.page = 1;
        this.courseList = [];
        this.getList();
      },
      Config.sidebarSearchDebounceDuration, true
    ),
    changeSelectTag(val, prop) {
      // 选中值发生变化
      let { tag_list } = this.dialogDetail.item;
      let newVal = val.filter(v => {

        return !tag_list.includes(v)
      });

      // this.dialogDetail.oldTag.push(...newVal);
      newVal.forEach(v => {
        addCourseTag(v).then(res => {
          if (res.errorcode == 0) {
            this.dialogDetail.item.tag_list.push(res.data.tag_name);
            this.getTagList();
          }
        })
      })
    },
    remoteTags(query) {
      // 远程搜索标签
      if (query === '') {
        this.fileTagList = this.tagList;
        return;
      }
      this.GetCourseTagList(query).then(res => {
        if (res.errorcode == 0) {
          this.fileTagList = res.data.data;
          this.$forceUpdate();
        }
      }).catch();
    },
    changeTags(val) {
      // 通过标签搜索
      this.page = 1;
      this.courseList = [];
      this.tag_name = val;
      this.getList();
    },
    updateItem({ course_id, index, propName, value }) {
      // 更新课程某一项
      if (!propName || value === undefined) return;

      let list = this.courseList;
      if (list[index].course_id === course_id) {
        this.courseList[index][propName] = value;
        return;
      }

      //index不符合时,查找
      let targetIndex = this.courseList.findIndex(v => v.course_id == course_id);
      if (targetIndex != -1) {
        this.courseList[targetIndex][propName] = value;
      }
    },
    handleClose(done) {
      done();
      this.inputVal = "";
      this.dialogDetail.index = -1;
    },
    delShare() {
      let item = this.dialogDetail.item;
      this.delVisible = false;
      this.DeleteShare(item.id).then(res => {
        this.dialogDetail.item = {};
        if (res.errorcode == 0) {
          this.$message("删除成功!");
          // 重新加载分享列表
          this.page = 1;
          this.getList();
        }
      }).catch(err => {
        this.dialogDetail.item = {};
      });
    },
    copyCourse(course_id) {
      this.CopyCourse(course_id).then(res => {
        if (res.errorcode == 0) {
          this.$message("复制成功!");
          // 重新加载课程列表
          this.page = 1;
          this.getList();
        }
      }).catch(err => {
      });
    },
    save() {
      //保存 弹窗
      let value = this.inputVal;
      let type = this.dialogDetail.type; //保存类型 rename 重命名课程名称；addlabel 给课程添加标签
      this.dialogVisible = false;

      let { course_id } = this.dialogDetail.item;

      if (type === 'addlabel') {
        // 添加标签
        updateCourseTag({
          course_id: course_id,
          tag_name_list: this.dialogDetail.oldTag,
        }).then(res => {
          if (res.errorcode == 0) {
            this.$message.success(res.msg);

            res.data.tag_list.forEach((v, vind) => {
              if (v.tag_name === undefined) {
                res.data.tag_list[vind].tag_name = v.tag_info.tag_name;
              }
            });
            // 更新list中的名称
            this.updateItem({ course_id, index: this.dialogDetail.index, propName: "tag_list", value: res.data.tag_list });
          } else {
            this.$message.warning(res.msg);
          }
        })

      }
    },
    getList() {
      // 获取课程列表
      let params = { keyword: this.keyword, sort_mode: this.sort_mode, page: this.page, limit: this.limit };
      if (this.tag_name && this.tag_name.length > 0) {
        this.tag_name.forEach((v, i) => {
          params[`tag_name_list[${i}]`] = v;
        })
      }

      this.GetShareCourseList(params).then((res) => {
        if (res.errorcode == 0) {
          this.courseList = this.page > 1 ? this.courseList.concat(res.data) : (res.data || []);
          this.total = res.total;
        } else {
          this.total = -1;
        }
      }).catch(() => {
        this.total = -1;
      });
    },
    commandVal(type, item, index) {
      return {
        type: type,
        item: item,
        index: index
      };
    },
    changeOrder(val) {
      // 排序方式
      this.sort_mode = val;
      this.page = 1;
      this.getList();
    },
    changeStyle(ind) {
      if (this.currentStyle == ind) return;
      this.currentStyle = ind;
    },
    toolshover(val, ind) {
      // 鼠标移入工具条
      this.showIndex = ind;
      this.isshow = val == 1 ? true : false;
    },
    handleTools({ type, item, index }) {
      // 工具栏 -删除、添加标签

      this.dialogDetail.item = {};

      switch (type) {
        case "delete":
          this.dialogDetail.title = "删除";
          this.dialogDetail.index = index;
          this.dialogDetail.item = item;
          this.delVisible = true;
          break;
        case "addlabel":
          this.dialogDetail.index = index;
          this.dialogDetail.item = item;
          this.dialogDetail.oldTag = item.tag_list.map(v => v.tag_name);

          this.dialogDetail.type = "addlabel";
          this.dialogDetail.title = "显示标签";
          this.dialogDetail.placeholder = "请输入标签，回车建立标签";
          this.dialogDetail.label = "标签名称";
          this.dialogVisible = true;
          break;
      }
    },
    toolsVisibleChange(ind) { },
    getUserInfo(){
      this.GetUserInfo().then(res=>{
        if (res.errorcode == 0 && res.data.version) {
          let { vid } = res.data.version;
          if(vid == 1) {
            this.$set(this.shareData, 'is_vip', 0);
            this.$set(this.shareData, 'open_way', "open");
            this.$set(this.shareData, 'valid_type', "always");
            this.$forceUpdate();
          }
        }
      })
    },
    toShare(){
      this.shareData=''
      this.entryType='new';
      this.shareVisible=true;

      this.shareData={
        code: '', 
        title: '',
        open_way: '',
        code_type: '',
        valid_type: '',
        teloremail: {
          email: [],
          tel: [],
        },
        valid_time: '',
        valid_days: '',
        if_reset_url: 0,
        is_vip:1
      }
      this.getUserInfo();
    },
    shareLink(item,t) {
      if (item.is_valid == 2) {
        return false
      }
      this.shareData={}
      this.ShareDetail(item.id)
        .then((res) => {
          if (res.errorcode==0) {
            this.shareData = res.data;
            if(t){
              this.shareData.is_update=t;
            }
            this.shareLinkVisible = true;
          }
        }).catch((err) => {

        });
    },
    shareSet(item) {
      let that =this;
      this.entryType='set';
      this.shareData={}
      this.ShareDetail(item.id)
        .then((res) => {
          if (res.errorcode==0) {
            if (!res.data.teloremail || res.data.teloremail.length==0) {
              res.data.teloremail={tel:[],email:[]}
            }
            this.shareData = {
              title:item.course_info?item.course_info.title:'',
              if_reset_url:0,
              ...res.data,
            };
            that.shareVisible = true;
          }
        }).catch((err) => {

        });
    },
    updateLink(id,t){
      this.page=1;
      // if(t==2){ //更新链接也要刷新列表
      this.getList()
      // }
      this.shareLink({id:id,is_valid:1},t)
    }
  },
};
</script>

<style lang="less" scoped>
.nodata {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  .statu_img {
    margin-top: -50px;
    width: 91px;
    height: 96px;
  }
}

.el-select {
  width: 160px;
}

.el-dropdown-menu {
  text-align: left;
  min-width: 106px;
}

.course-container {
  box-sizing: border-box;
  padding: 32px 0 5px 32px;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  height: 100%;
  background-color: #FAFAFA;
}

.list-toolbar {
  padding-right: 32px;
}
.image-slot{
  width: 100%;
  height: 100%;
  img{
    width: 100%;
  height: 100%;
  }
}

@liMarginRight: 24px;

.list-container {
  padding-right: 32px;
  height: 100%;
  overflow: hidden auto;
  // 滚动条占宽会导致页面对不齐
  &::-webkit-scrollbar {
    width: 0;
    height: 0;
  }
  /deep/.el-scrollbar__wrap {
    width: 100%;
    margin-right: 0 !important;
    &::-webkit-scrollbar {
      width: 0;
      height: 0;
    }
  }
  &.list_nodata /deep/.el-scrollbar__view {
    height: 100%;
  }
  
  // 网格方式
  &.grid_type {
    /deep/.el-scrollbar__view {
      // display: flex;
      // flex-wrap: wrap;
      // align-content: flex-start;
      // margin-right: calc(var(--right)); //抵消li的margin-right
      display: grid;
      grid-template-columns: repeat(auto-fill,minmax(224px,1fr));
      gap: 24px;
    }
    /deep/.el-scrollbar__bar.is-horizontal {
      display: none;
    }
    @media screen and (max-width: 1600px) {
      /deep/.el-scrollbar__view {
        grid-template-columns: repeat(auto-fill,minmax(200px,1fr));
      }
    }
  }
  
}

.coursebox-wrapper {
  // overflow: hidden;
  // margin-bottom: 24px;
  // margin-right: @liMarginRight;
  box-sizing: border-box;
  border-radius: 4px;
  border: 1px solid #e4e4e4;
  // width: 260px;
  // height: 267px;

  &:hover {
    box-shadow: 0 1px 10px rgba(0, 0, 0, 0.12);

    .coursebox-mask {
      opacity: 1;
    }
  }
}

.coursebox-image {
  display: block;
  border-radius: 4px 4px 0 0;
  width: 100%;
  padding-top: 65%;
  background-color: #f3f3f3;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  // width: 258px;
  // height: 155px;
}

.coursebox-tools {
  background-color: #fff;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-top: 1px solid #F0F0F0;
  border-radius: 0px 0px 4px 4px;

  &-btn {
    color: #E74362;
    height: 30px;
    // min-width: 104px;
    width: calc(50% - 16px);
    line-height: 30px;
    text-align: center;
    font-size: 12px;
    border-radius: 16px;
    border: 1px solid #E74362;
    background-color: #fff;
    transition: all 0.3s;

    &:hover {
      background-color: #E74362;
      color: #fff;
    }

    &.btn-disabled {
      color: #BFBFBF;
      background: #F5F5F5;
      border: 1px solid #D9D9D9;
      cursor: not-allowed;
    }
  }

}

.coursebox-mask {
  opacity: 0;
  transition: opacity 0.6s;
  position: absolute;
  top: 0;
  left: 0;
  border-radius: 4px 4px 0 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.40);

  &.show {
    opacity: 1;
  }
}

.coursebox-mask-edit {
  position: absolute;
  right: 0;
  z-index: 100;
  width: 52px;
  height: 36px;
  line-height: 36px;
  text-align: center;

  .icon-gray-more::before {
    color: #fff;
  }
}

.coursebox-mask-tools {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  i {
    font-size: 16px;
  }

  span {
    line-height: 20px;
    margin-top: 4px;
  }
}


.coursebox-title {
  margin-top: 16px;
  box-sizing: border-box;
  width: 228px;
  height: 20px;
  line-height: 20px;
}

.coursebox-title /deep/p {
  width: 220px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

// 预览加载
.preview-loading-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
}

.loading-img {
  width: 150px;
  height: 150px;
  object-fit: contain;
}

.cancel-btn {
  border: 1px solid #D4D4D4;
  border-radius: 16px;
  margin-top: 40px;
  width: 78px;
  height: 32px;
  transition: background 0.6s;
  cursor: pointer;

  &:hover {
    background-color: #EDEDED;
  }
}
</style>
