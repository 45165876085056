<template>
  <div>
    <el-dialog title="分享链接" custom-class="share-link-dialog" top="7.5vh" width="790px" :append-to-body="true"
      :visible.sync="linkVisible" @close="outerClose">
      <div slot="title" class="color_26"></div>
      <div class="flex flex_acenter flex_center flexcolumn border_bottomF0 padding_bot24 margin_bot24" v-if="shareData.is_update">
        <span class="complete-status">
          <i class="icon-upgrade-win fontsize_24"></i>
        </span>
        <span class="lineheight_20 fontsize_14 color_59">{{shareData.is_update==1?'分享链接已更新！':'分享链接已生成！'}}</span>
        <a class="color_E74362 fontsize_14 lineheight_20 margin_top4 pointer" @click="toShareCenter" v-if="shareData.is_update!=1">请前往“分享中心”查看>> </a>
      </div>
      <div class="margin_bot24 padding_left24 padding_right24">
        <label class="fontsize_14 color_59 lineheight_20 margin_bot8 flex">课程链接：</label>
        <div class="flex flex_jcbetween flex_acenter">
          <el-input placeholder="请输入内容" v-model="shareData.url" class="link-input color_BFBFBF" disabled>
            <template slot="append"><span v-clipboard:copy="shareData.url" v-clipboard:success="copySuccess"
                v-clipboard:error="copyErr">复制链接</span></template>
          </el-input>
          <customButton plain :roundPX="4" color="#E74362" :width="88" :height="32" @click="openLink"
            class="sureBtn pointer margin_left16 flexshrink openLinkBtn">
            <span class="fontsize_14">打开链接</span>
          </customButton>
        </div>
      </div>
      <div class="margin_bot24 padding_left24 padding_right24" style="display:grid;grid-template-columns: 1fr 1fr;">
        <div class="">
          <label class="fontsize_14 color_59 lineheight_20 margin_bot10 flex">课程二维码：</label>
          <div class="flex flex_acenter flex_center" style="height:120px;">
            <el-image class="share-code" :src="shareData.course_qrcode_base64">
            </el-image>
            <customButton :roundPX="4" color="#E74362" :width="102" :height="32" @click="saveCode"
              class="sureBtn pointer margin_left16 saveCodeBtn">
              <span class="fontsize_14">保存二维码</span>
            </customButton>
          </div>
        </div>
        <div class=" left-bd prel padding_left24" v-if="shareData.open_way=='code'">
          <label class="fontsize_14 color_59 lineheight_20 margin_bot10 flex">课程访问码：</label>
          <div class="flex flex_acenter flex_center" style="height:120px;">
            <customButton :roundPX="4" color="#F5F5F5" :width="100" :height="32"
              class="sureBtn pointer margin_left16 bdd9">
              <span class="fontsize_14 color_BFBFBF">{{shareData.code}}</span>
            </customButton>
            <customButton :roundPX="4" color="#E74362" :width="102" :height="32" v-clipboard:copy="shareData.code"
              v-clipboard:success="copySuccess" v-clipboard:error="copyErr" class="sureBtn pointer margin_left16 saveCodeBtn">
              <span class="fontsize_14">复制访问码</span>
            </customButton>
          </div>
        </div>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import customButton from "@/renderer/components/common/button.vue";
import { mapState } from 'vuex';
export default {
  components: {
    customButton,
  },
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    shareData: {
      type: Object,
      default: () => {
        return {
          code: '',
          valid_type: '',
          course_url: '',
          course_qrcode_base64: ''
        }
      },
    }
  },
  data() {
    return {
      linkVisible: false,
    };
  },
  watch: {
    visible(nval) {
      if (nval) {
        this.linkVisible = true;
      } else {
        this.linkVisible = false;
      }
    },
  },
  computed: {
    ...mapState({
      is_web: state => state.app.isWebSide,
    }),
  },
  methods: {
    outerClose() {
      this.$emit('update:visible', false)
    },
    saveBas64Img (url, name) {
      let base64 = url.toString()
      let byteCharacters = atob(
        base64.replace(/^data:image\/(png|jpeg|jpg);base64,/, "")
      )
      let byteNumbers = new Array(byteCharacters.length)
      for (let i = 0; i < byteCharacters.length; i++) {
        byteNumbers[i] = byteCharacters.charCodeAt(i)
      }
      let byteArray = new Uint8Array(byteNumbers)
      let blob = new Blob([byteArray], {
        type: undefined
      })
      let aLink = document.createElement("a")
      aLink.download = `${name}.png` //这里写保存时的图片名称
      aLink.href = URL.createObjectURL(blob)
      aLink.click()
    },
    saveCode() {
      let baseImg =this.shareData.course_qrcode_base64;
      this.saveBas64Img(baseImg,'course-code-' + new Date().getDate())

    },
    copySuccess() {
      this.$message.success('复制成功');
    },
    copyErr() {
      this.$message.error('复制失败');
    },
    openLink() {
      let { url, p, open_way, course_url } = this.shareData;
      
      window.open(url, "_blank");
      // if(open_way !== "open") {
      //   this.directOpen({ p, path: "/sharecourseofpreview" });
      //   return;
      // }
      
      // this.directOpen( { p, path: "/enview" } );
    },
    directOpen({ p, path }) {
      let { href } = this.$router.resolve({
        path: path,
        query:{
          p: p
        }
      });
      window.open(href, "_blank");
    },
    toShareCenter(){
      this.$emit('update:visible', false)
      this.$router.push({name:'Share'});
    }
  },
  mounted() { },
  destroyed() {
    this.$emit('update:visible', false)
  },
};
</script>
<style lang="less" scoped>
/deep/.share-link-dialog {
  border-radius: 4px;

  .el-dialog {
    &__body {
      padding: 24px 0;
    }

    &__header {
      border-radius: 4px 4px 0 0;
      height: 40px;
      padding: 0 24px;

      &btn {
        top: 10px;
        right: 16px;
        color: #919191;
      }
    }
  }

  /deep/.el-input {
    height: 32px;
  }

  .bdr4 {
    border-radius: 4px;
  }

  .bdd9 {
    border: 1px solid #D9D9D9 !important;
  }

  .left-bd::before {
    content: '';
    width: 1px;
    height: 142px;
    background: #F0F0F0;
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
  }

  .complete-status {
    width: 40px;
    height: 40px;
    background: #52C41A;
    margin-bottom: 12px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
  }

  .custom-code {
    width: 130px;
    height: 20px;
    font-size: 14px;
    color: #bfbfbf;
    background: #ffffff;
    border: 1px solid #d9d9d9;
    border-radius: 2px;
    margin-left: 4px;

    &::placeholder {
      color: #bfbfbf;
    }
  }

  .day-input {
    width: 62px;
    height: 24px;
    border-radius: 2px;
    border: 1px solid #d9d9d9;
    box-sizing: border-box;

    display: flex;
    align-items: center;
    overflow: hidden;
    margin: 0 4px;

    .input {
      border: none;
      width: 36px;
      height: 100%;
      padding: 0;
      padding-left: 8px;
      box-sizing: border-box;
      font-size: 14px;
      color: #595959;

      &::-webkit-inner-spin-button,
      &::-webkit-outer-spin-button {
        -webkit-appearance: none !important;
      }

      &[type="number"] {
        -moz-appearance: textfield;
      }
    }
  }

  .share-code {
    width: 100px;
    height: 100px;

    /deep/.el-image__inner {
      object-fit: cover;
    }
  }
}

.margin_left100 {
  margin-left: 100px;
}
</style>
<style lang="less">
.link-input.el-input {
  height: 32px;
  line-height: 32px;
  border-radius: 4px;
}

.link-input.el-input .el-input__inner {
  border: none;
  background: #f5f5f5;
  border: 1px solid #d9d9d9;
  color: #BFBFBF !important;
}

.link-input.el-input .el-input-group__append {
  height: 32px;
  background: #e74362;
  font-size: 14px;
  color: #ffffff;
  border: none;
  cursor: pointer;
  &:hover {
    background: #B5102F;
  }
}

.saveCodeBtn.custom-btn {
  &:hover {
    background: #B5102F !important;
  }
}

.openLinkBtn.custom-btn {
  &:hover {
    border-color: #B5102F !important;
    color: #B5102F !important;
  }
}
</style>
